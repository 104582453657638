<template>
  <div class="page">
    <li class="info-li">
      <div class="info-item">
        <p>昵称</p>
        <span>{{ userInfo.nickName }}</span>
      </div>
      <div class="info-item">
        <p>联系方式</p>
        <span>{{ userInfo.userMobile }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>用户ID</p>
        <span>{{ userInfo.userId }}</span>
      </div>
      <div class="info-item">
        <p>所在门店</p>
        <span>{{ userInfo.shopName }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>最近访问时间</p>
        <span>{{ userInfo.updDt }}</span>
      </div>
      <div class="info-item">
        <p>最新订单</p>
        <span>{{ (userInfo.startTime||'')+(userInfo.startTime?' ~ ':'')+(userInfo.endTime||'')  }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>累计完成订单时长</p>
        <span>{{ userInfo.total || "0" }}</span>
      </div>
      <div class="info-item">
        <p>拥有门店卡券</p>
        <span>{{
          userInfo.memberCardVoList ? userInfo.memberCardVoList.length : 0
        }}张</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>账户余额</p>
        <span>本金：¥{{ userInfo.balanceAmount }} ｜门店赠金：¥{{
          userInfo.giveAmount
        }}</span>
      </div>
      <div class="info-item">
        <p>门店充值概况</p>
        <span>本金：¥{{ userInfo.shopBalanceAmountTotal }} ｜门店赠金：¥{{
          userInfo.shopGiveAmountTotal
        }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>历史累计充值本金</p>
        <span>{{ '￥'+userInfo.historyTotalRecharge }}</span>
      </div>
      <div class="info-item">
        <p>累计充值（本金）</p>
        <span>{{ '￥'+userInfo.totalRecharge }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item">
        <p>成长值</p>
        <span>{{userInfo.memberLevelVo?userInfo.memberLevelVo.experience:'' }}</span>
      </div>
      <div class="info-item">
        <p>会员等级</p>
        <span>{{ userInfo.memberLevelVo.levelConfigVo?userInfo.memberLevelVo.levelConfigVo.levelName:'-' }}</span>
      </div>
    </li>
    <li class="info-li">
      <div class="info-item info-item2">
        <p>消费总额</p>
        <span>微信消费：¥{{ userInfo.wechatConsumeAmount }} ｜本金消费：¥{{
          userInfo.balanceConsumeAmount
        }}｜赠金消费：¥{{ userInfo.giveConsumeAmount }} ｜线下消费：¥{{
          userInfo.cashConsumeAmount
        }}｜团购消费：¥{{ userInfo.buyConsumeAmount }}</span>
      </div>
    </li>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/user/user";
export default {
  data() {
    return {
      userInfo:{},
      tableParams: {
        shopId: "",
        userId: "",
      }
    };
  },
  created() {
    this.tableParams.userId = this.$route.query.userId;
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getUserInfo();
  },
  methods: {
    // 【请求】添加
    getUserInfo() {
      
      getUserInfo(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.userInfo = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-item {
  width: 500px;
  height: 48px;
  margin: 15px;
  border: 1px solid #eee;
  display: inline-flex;

  p {
    min-width: 200px;
    background: #f9f9f9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: inline-flex;
    align-items: center;
    padding: 0 50px;
  }
}

.info-item2 {
  width: 1030px;
}
</style>